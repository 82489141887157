import { useEffect, useState } from 'react';
import { Button, Heading, P, AnchorButton } from 'components';
import { useRedirect } from 'hooks';
import { postData } from 'helpers/post-data';
import { NavigationTarget } from 'data'
import { groupIdInUrl, mustBeSignedIn, builder } from 'routes'
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setErrorMessage } from '../../redux/contactsslice';

import BaseLayout from 'blocks/layout/base-layout';
import './index.scss';

interface EmailField {
  id: number;
  value: string;
}

interface AddContactsNonGmailProps {
  groupId: GroupId;
}

export const AddContactsNonGmail = (props: AddContactsNonGmailProps) => {
  const { groupId } = props as { groupId: GroupId }
  const inviteMessage = useAppSelector(state => state.invite.message);
  const [emailFields, setEmailFields] = useState<EmailField[]>([
    { id: 1, value: '' },
    { id: 2, value: '' },
    { id: 3, value: '' },
  ]);
  const dispatch = useAppDispatch();
  const redirect = useRedirect()
  const [isValid, setIsValid] = useState<boolean>(true);

  const handleEmailChange = (id: number, value: string) => {
    const updatedFields = emailFields.map(field =>
      field.id === id ? { ...field, value } : field
    );
    setEmailFields(updatedFields);
  };

  const handleAddField = () => {
    if (emailFields.length < 10) {
      setEmailFields([...emailFields, { id: emailFields.length + 1, value: '' }]);
    }
  };

  const handleInviteClick = async () => {
    const enteredEmails = emailFields.map(field => field.value).filter(Boolean);
    if (enteredEmails.length < 3) {
      setIsValid(false);
      return;
    }
    dispatch(setErrorMessage(null));

    try {

      const response = await postData('/api/group/inviteMembers', {
        groupId: groupId,
        message: inviteMessage,
        contacts: enteredEmails.map(email => ({
          fullName: '',
          email: email,
        })),
      })


      console.log('Invites sent successfully:', response);
      redirect(new NavigationTarget({
        url: builder.group.inviteByEmailSuccess(groupId),
      }))
    } catch (error) {
      console.error('Error sending invites:', error);
      dispatch(setErrorMessage('Failed to send invites. Please try again.'));
    }
  };

  useEffect(() => {
    postData('/api/analytics/track', { funnel: "GROUP_CREATE", event: "APP_GC_INVITE_ADD_CONTACTS", url: window.location.href })
  }, [])

  return (
    <BaseLayout>
      <div className="add-contacts-non-gmail font-16px">
        <Heading>Invite 3 Contacts</Heading>
        <P classes="pd-top-btm--md">Please enter at least 3 email addresses below to invite your friends & family.</P>

        <div className="email-fields">
          {emailFields.map(field => (
            <input
              key={field.id}
              type="email"
              placeholder={`Email Address ${field.id}`}
              value={field.value}
              onChange={(e) => handleEmailChange(field.id, e.target.value)}
              className={!isValid && field.id <= 3 && !field.value ? 'invalid' : ''}
            />
          ))}
        </div>

        <AnchorButton onClick={handleAddField} extraClass="d-flex j-center pd-top-btm--md">+ Add Another Email</AnchorButton>

        <Button type="button" onClick={handleInviteClick} extraClass="mg-top--lg" text="Send Invites" />

        {!isValid && (
          <P classes="error-message">Please enter at least 3 valid email addresses.</P>
        )}

        <P>Only the people you select will receive an invitation to your group.</P>
      </div>
    </BaseLayout>
  );
};

export default mustBeSignedIn(groupIdInUrl(AddContactsNonGmail))
