import React, { useEffect, useState, useRef, forwardRef } from 'react'
import classNames from 'classnames'

import { FormRow } from 'components'

import './index.scss'

/**
 * Managed TextArea component.
 *
 * Generally the same as TextField; see it for details about internals.
 */
interface Props {
  binding: FormBinding<string>
  errorMessage?: React.ReactNode
  onBlur?: React.FocusEventHandler
  onFocus?: React.FocusEventHandler
  placeholder: string
  required?: boolean
  disabled?: boolean
  noScrollHeight?: boolean
  validationMessage?: string
  onKeyUp?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void
  style?: React.CSSProperties;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}
const TextArea: React.FC<Props> = ({
  binding,
  disabled,
  errorMessage,
  onBlur,
  onFocus,
  noScrollHeight,
  placeholder,
  required = false,
  validationMessage = '',
  onKeyUp,
  style,
  onChange: parentOnChange,
  ...props
}) => {
  // Hide errors initially - this ensures they're only displayed in a timely
  // manner (i.e. on blur)
  const [mayShowError, setMayShowError] = useState(false)

  // Ref allows access to native HTML validation rules
  const ref = useRef<HTMLTextAreaElement>(null)

  const bindingSetIsValid = binding.setIsValid

  // Revalidate as the value changes
  useEffect(() => {
    const input = ref.current
    if (!input) {
      return
    }
    const valid = input.checkValidity()
    bindingSetIsValid(valid)
  }, [binding.value, bindingSetIsValid])


  useEffect(() => {
    const input = ref.current
    if (input) {
      input.style.height = 'auto';

      if (noScrollHeight) { input.style.height = '32px'; }
      input.style.height = `${input.scrollHeight + 2}px`;
    }
  }, [binding.value]);

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    binding.setValue(e.target.value);

    if (parentOnChange) {
      parentOnChange(e);
    }
  };

  const internalOnBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    binding.setValue(binding.value.trim())
    // Only display errors if the element has lost focus. This avoids
    // disruptive feedback while the user is typing.
    setMayShowError(true)
    onBlur?.(e)
  }

  const internalOnFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    onFocus?.(e)
  }

  const showValidationError = errorMessage || (mayShowError && !binding.isValid)

  const validationError = <span className="ErrorMessage">{errorMessage || validationMessage}</span>

  return (
    <FormRow>
      <div className={classNames("TextArea", { invalid: showValidationError })}>
        <textarea
          onBlur={internalOnBlur}
          onFocus={internalOnFocus}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
          ref={ref}
          required={required}
          value={binding.value}
          onKeyUp={onKeyUp}
          style={style}
        />
        {showValidationError && validationError}
      </div>
    </FormRow>
  )
}

export default TextArea
