import * as config from 'config';
import React, { useEffect, useState, useMemo } from 'react';
import { Button, Heading, P, Form, Icon, TextArea } from 'components';
import { groupIdInUrl, mustBeSignedIn, builder } from 'routes';
import { useFormField, useRedirect } from 'hooks';
import { NavigationTarget } from 'data'
import invite from 'assets/images/email-invite.svg';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { postData } from 'helpers/post-data'
import { setContacts, toggleContactSelection, setInputText, setErrorMessage } from '../../redux/contactsslice';
import Fuse from 'fuse.js';
import { gapi } from 'gapi-script';
import BaseLayout from 'blocks/layout/base-layout';
import './index.scss';

const CLIENT_ID = config.REACT_APP_GOOGLE_CLIENT_ID;
const SCOPES = config.REACT_APP_GOOGLE_SCOPES;
const DISCOVERY_DOCS = [config.REACT_APP_GOOGLE_DISCOVERY_DOCS];

interface Contact {
  id: string;
  name: string;
  email: string;
  selected: boolean;
}

interface LoadContactsGmailProps {
  groupId: GroupId;
}

export const LoadContactsGmail = (props: LoadContactsGmailProps) => {
  const { groupId } = props as { groupId: GroupId }
  const inputValue = useFormField<string>('');
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const dispatch = useAppDispatch();
  const { contacts, selectedContacts, inputText, errorMessage } = useAppSelector(state => state.contacts);
  const inviteMessage = useAppSelector(state => state.invite.message);

  const redirect = useRedirect()

  const fuse = useMemo(() => new Fuse(contacts, {
    keys: ['name', 'email'],
    threshold: 0.3,
  }), [contacts]);

  useEffect(() => {
    postData('/api/analytics/track', { funnel: "GROUP_CREATE", event: "APP_GC_INVITE_SIGNIN_GMAIL", url: window.location.href })
  }, [])

  useEffect(() => {
    const selectedContactsList = selectedContacts.map(contact => contact.name).join(', ');
    if (selectedContactsList !== inputText) {
      dispatch(setInputText(selectedContactsList));
    }
    if (!selectedContactsList.length) setSearchTerm('');
  }, [selectedContacts, dispatch]);

  useEffect(() => {
    inputValue.setValue(inputText)

    const textHeight = document.querySelector('.load-contacts-gmail textarea') as HTMLTextAreaElement;
    if (textHeight) {
      const listHeight = document.querySelector('.load-contacts-gmail .contacts-list') as HTMLTextAreaElement;
      if (window.innerWidth < 768) {
        listHeight.style.height = `calc(100vh - 196px - ${textHeight.scrollHeight}px)`
      } else {
        listHeight.style.height = `calc(476px - ${textHeight.scrollHeight}px)`
      } 
    }    

    console.log(window.innerWidth)
  }, [inputText, inputValue]);

  const handleContactSelect = (id: string) => {
    dispatch(toggleContactSelection(id));  
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    dispatch(setInputText(value));
    const lastTerm = value.split(',').pop()?.trim();
    setSearchTerm(lastTerm || '');  
    deleteContactFromTextarea(value)  
  };

  const addListComma = () => {
    if (inputText.length < 1 || inputText.endsWith(', ')) return;
    dispatch(setInputText(inputText + ', '));
  };

  const handleInviteClick = async () => {
    if (selectedContacts.length < 3) {
      dispatch(setErrorMessage('Please select at least 3 contacts.'));
      return;
    }
    dispatch(setErrorMessage(null));

    try {

      const response = await postData('/api/group/inviteMembers', {
        groupId: groupId,
        message: inviteMessage,
        contacts: selectedContacts.map(contact => ({
          fullName: contact.name,
          email: contact.email,
        })),
      })

      console.log('Invites sent successfully:', response);
      redirect(new NavigationTarget({
        url: builder.group.inviteByEmailSuccess(groupId),
      }))
    } catch (error) {
      console.error('Error sending invites:', error);
      dispatch(setErrorMessage('Failed to send invites. Please try again.'));
    }
  };

  useEffect(() => {
    function initClient() {
      gapi.client.init({
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      }).then(() => {
        const authInstance = gapi.auth2.getAuthInstance();
        const isSignedIn = authInstance.isSignedIn.get();
        setIsSignedIn(isSignedIn);

        // Listen for sign-in state changes
        authInstance.isSignedIn.listen(setIsSignedIn);
        if (isSignedIn) {
          loadContacts();
        }
      }).catch((error: any) => {
        console.error('Error during Google API client initialization:', error);
      });
    }

    gapi.load('client:auth2', initClient);
  }, []);

  const handleSignIn = () => {
    const authInstance = gapi.auth2.getAuthInstance();
    postData('/api/analytics/track', { funnel: "GROUP_CREATE", event: "APP_GC_INVITE_SIGNIN_GMAIL_CLICK", url: window.location.href })
    authInstance.signIn().then(() => {
      setIsSignedIn(true);
      loadContacts();
    }).catch((error: any) => {
      console.error('Google Sign-In Error:', error);
    });
  };

  const loadContacts = async () => {
    try {
      const [mainContacts, otherContacts] = await Promise.all([fetchMainContacts(), fetchOtherContacts()]);
      const allContacts = [...mainContacts, ...otherContacts].sort((a, b) => a.name.localeCompare(b.name));
      postData('/api/analytics/track', { funnel: "GROUP_CREATE", event: "APP_GC_INVITE_ADD_CONTACTS", url: window.location.href })
      dispatch(setContacts(allContacts));
    } catch (error) {
      dispatch(setErrorMessage('Error fetching contacts'));
    }
  };

  const fetchMainContacts = async (): Promise<Contact[]> => {
    try {
      const response = await gapi.client.people.people.connections.list({
        resourceName: 'people/me',
        pageSize: 50,
        personFields: 'names,emailAddresses',
      })
      return parseContacts(response.result.connections || []);
    } catch (error) {
      console.error('Error fetching main contacts:', error);
      throw error;
    }
  };

  const fetchOtherContacts = async (): Promise<Contact[]> => {
    try {
      const response = await gapi.client.people.otherContacts.list({
        pageSize: 100,
        readMask: 'names,emailAddresses',
      })
      return parseContacts(response.result.otherContacts || []);
    } catch (error) {
      console.error('Error fetching other contacts:', error);
      throw error;
    }
  };

  function getEmailPrefix(contact: string) {
    const emailPattern = /<([^\s@]+@[^\s@]+\.[^\s@]+)>|^([^\s@]+@[^\s@]+\.[^\s@]+)$/;
    const match = contact.match(emailPattern);
    if (emailPattern.test(contact)) {
      const emailAddress = match ? (match[1] || match[2]) : '';
      return emailAddress.split('@')[0];
    } else {
      return contact;
    }
  }

  const parseContacts = (contacts: any[]): Contact[] => {
    return contacts.map((person: any, index: number) => {
      const email = person.emailAddresses && person.emailAddresses[0]?.value;
      const name = (person.names && person.names[0]?.displayName) || 'Unknown';
      const displayName = getEmailPrefix(name);
      const letter = displayName.charAt(0).toUpperCase();

      return {
        id: `${index}-${email}`,
        name: displayName,
        email: email || '',
        selected: false,
        letter,
      };
    }).filter((contact: Contact) => contact.email);
  };

  const filteredContactsMemo = useMemo(() => {
    if (searchTerm) {
      return fuse.search(searchTerm).map(result => result.item);
    }
    return contacts
  }, [searchTerm, contacts, fuse])

  const deleteContactFromTextarea = (text: string) => {
    const contact = selectedContacts.filter(el => !text.includes(el.name))
    if (contact.length) {
      dispatch(toggleContactSelection(contact[0].id));
      setSearchTerm('');
    }
    return
  }

  const contactListWithLetterHeaders = (): JSX.Element | null => {
    let previousLetter = ''
    const letterDivs: JSX.Element[] = []

    filteredContactsMemo.forEach((contact, i) => {
      const currentLetter = contact.name.charAt(0)
      const index = i % 2 === 0 ? 'even' : 'odd'
      // Check if the current letter is different from the previous to add a header
      if (currentLetter !== previousLetter) {
        letterDivs.push(
          <div key={i} className="contact-header">
            {currentLetter}
          </div>
        )
        previousLetter = currentLetter
      }
      // Always add the contact name under the (possibly new) header
      letterDivs.push(
        <div key={contact.id} className={`contact-item ${index}`}  onClick={() => handleContactSelect(contact.id)}>
          <Icon icon={contact.selected ? 'checkedthin' : 'uncheckedthin'} size="medium" inline />
          <div>
            <div className="contact-name">{contact.name}</div>
            <div className="contact-email">{contact.email}</div>
          </div>
        </div>
      )
    })

    // Return a fragment containing all headers and names
    return letterDivs.length > 0 ? <>{letterDivs}</> : null
  }


  return (
    <BaseLayout hasSections>
      {!isSignedIn ? (
        <div className="add-contacts-gmail Section">
          <Heading>Add Contacts</Heading>
          <P>Access your Gmail contacts to select who will receive your invitation.</P>

          <img
            alt="Invite By Gmail"
            src={invite}
            style={{ userSelect: 'none' }}
            width="100%"
            className="mg-top--md mg-btm--md"
          />
          <div className="signin-section">
            <Button type="button" onClick={handleSignIn} text="Sign In with Google" />
            <P>Only the people you select will be invited to give.</P>
          </div>
        </div>

      ) : (
        <div className="load-contacts-gmail">
          <div className="load-contacts-header mg-btm--sm">
            <Heading>Add Contacts</Heading>
            <P classes="mg-top--xs font-w-reg">Search contacts by name below.</P>
          </div>
          <Form onSubmit={() => { }}>
            <div className="to-field d-flex pd-lfrt--lg">
              To: <TextArea placeholder="Type Contact Names Here..." binding={inputValue} onChange={handleInputChange} onFocus={addListComma} noScrollHeight />
            </div>
  
            <div className="contacts-list">
              {contactListWithLetterHeaders()}
              {errorMessage && <P classes="error-message">{errorMessage}</P>}
            </div>
            <div className="sticky">
              <Button extraClass="mg-top--lg" type="button" onClick={handleInviteClick} disabled={selectedContacts.length < 3} text="Invite Contacts" />
              {selectedContacts.length < 3 ? (
                <P classes="text-center font-semibold">Please add at least 3 contacts</P>
              ) : (
                <P classes="text-center font-semibold success-green">You are ready to send invites!</P>
              )}
            </div>
          </Form>
        </div>
      )}
    </BaseLayout>
  );
};

export default mustBeSignedIn(groupIdInUrl(LoadContactsGmail));
